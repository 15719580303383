import { Injectable, WritableSignal, inject, signal } from '@angular/core';
import { RequestHandlerParams } from '../../shared/types/api-service.types';
import { ApiResponse } from '../../shared/common/types';
import { ApiService } from '../../shared/api.service';
import { filter, finalize, tap } from 'rxjs';
import { SignalsStoreService } from '../../shared/signals-store.service';
import { StockService } from '../../stock/stock.service';

@Injectable({
  providedIn: 'root',
})
export class ProducersService {
  private apiService = inject(ApiService);
  private signalsStoreService = inject(SignalsStoreService);
  private StockService = inject(StockService);

  isLoadingProducers: WritableSignal<boolean> = signal(false);
  isLoadingProducts: WritableSignal<boolean> = signal(false);
  producersSignal: WritableSignal<any> = signal([]);
  producerDetailsSignal: WritableSignal<any> = signal(null);

  getAllProducers() {
    this.isLoadingProducers.set(true);
    const params: RequestHandlerParams = {
      method: 'GET',
      endpoint: '/producers',
      apiV3: true,
    };
    return this.apiService
      .handleRequest(params)
      .pipe(
        filter((response: any) => !!response.data),
        tap(({ data: producers }: ApiResponse<any>) => {
          this.producersSignal.set(producers);
        }),
        finalize(() => this.isLoadingProducers.set(false))
      )
      .subscribe();
  }

  getProducerByUrl(producerUrl: string) {
    this.isLoadingProducts.set(true);
    const params: RequestHandlerParams = {
      method: 'GET',
      endpoint: `/producers/${producerUrl}`,
      apiV3: true,
    };
    return this.apiService
      .handleRequest(params)
      .pipe(
        filter((response: any) => !!response.data),
        tap(({ data: producer }: ApiResponse<any>) =>
          this.producerDetailsSignal.set(producer)
        ),
        finalize(() => {
          this.isLoadingProducts.set(false);
        })
      )
      .subscribe();
  }

  setName(name?: string) {
    return this.signalsStoreService.filterByProducer.set(
      name ?? this.producerDetailsSignal()?.name
    );
  }
}
